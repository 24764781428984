import Link from 'next/link';
import { ReactNode } from 'react';

import { MonieWorldLogo } from '../monieworld-logo';

interface LinkWrapperProps {
  children: ReactNode;
  disableNavigation?: boolean;
  className?: string;
  href?: string;
}

interface HeaderLogoProps {
  className?: string;
  disableNavigation?: boolean;
  href?: string;
}

const LinkWrapper = ({
  children,
  disableNavigation,
  className,
  href,
}: Readonly<LinkWrapperProps>) =>
  disableNavigation ? (
    children
  ) : (
    <Link href={href ?? '/'} aria-label="MonieWorld Home" className={className}>
      {children}
    </Link>
  );

export function HeaderLogo({
  className,
  disableNavigation,
  href,
}: Readonly<HeaderLogoProps>) {
  return (
    <LinkWrapper
      href={href}
      disableNavigation={disableNavigation}
      className={className}
    >
      <MonieWorldLogo size="small" className="h-units-unit40" />
    </LinkWrapper>
  );
}
