'use client';

import { Button } from '@kamona/components';

import { useSuppportModal } from '@/features/shared/support/modal';

import { cn } from '@/lib/utils';

type HeaderHelpChipProps = {
  onClick?: () => void;
  className?: string;
};

export function HeaderHelpChip({
  onClick,
  className,
}: Readonly<HeaderHelpChipProps>) {
  const { toggleSupportModal } = useSuppportModal();

  return (
    <Button
      variant="outline"
      appearance="plain"
      size="small"
      onClick={onClick ?? toggleSupportModal}
      className={{ root: cn('pl-padding6 pr-padding6 box-border', className) }}
    >
      Help
    </Button>
  );
}
