'use client';

import { Subtitle } from '@kamona/components';
import { useIsScreenUp } from '@kamona/utils-v2';
import React from 'react';

import { BaseHeader } from '@/ui/components/header';
import { HeaderCloseIconButton } from '@/ui/components/header/components';
import { ProfileBadge } from '@/ui/components/profile-badge';

import { ZendeskConfigScript } from '@/config';

type SupportHeaderProps = {
  onClose?: () => void;
  insideModal?: boolean;
};

export const SupportHeader = ({ onClose, insideModal }: SupportHeaderProps) => {
  const isDesktop = useIsScreenUp('md');

  return (
    <>
      <ZendeskConfigScript />
      <BaseHeader
        className="px-units-unit16 py-units-unit10 md:px-fluid-header-px md:py-fluid-header-py"
        leftElement={
          <HeaderCloseIconButton onClose={onClose} insideModal={insideModal} />
        }
        rightElement={isDesktop ? <ProfileBadge /> : <span aria-hidden />}
      >
        {!isDesktop && (
          <Subtitle color="content-heading-default" weight="semibold">
            Help centre
          </Subtitle>
        )}
      </BaseHeader>
    </>
  );
};
