'use client';

import { Header, Subtitle } from '@kamona/components';
import React from 'react';

import { useProfileContext } from '@/features/shared/profile/providers';
import { SupportWidgetsWithFaqs } from '@/features/shared/support/shared/components';

import { Page, PageCover } from '@/ui/components/page';

export const SupportPageComponent = () => {
  const { profile } = useProfileContext();

  // TODO: Find out why profile isn't available here
  const title = profile?.firstName
    ? `Hi ${profile.firstName}, how can we help you today?`
    : 'Hi, how can we help you today?';

  return (
    <Page container="xl">
      {/* Default cover */}
      <PageCover
        alt="Support agent against monitors"
        src="/assets/images/illustrations/support-agent-against-monitors/default.png"
        className={{ root: 'md:hidden' }}
      />
      {/* Desktop cover */}
      <PageCover
        alt="Support agent against monitors"
        src="/assets/images/illustrations/support-agent-against-monitors/md.png"
        className={{ root: 'hidden md:block' }}
      />
      {/* Default */}
      <Subtitle
        as="h1"
        color="content-heading-default"
        weight="semibold"
        className="md:hidden"
      >
        {title}
      </Subtitle>
      {/* Desktop cover */}
      <Header
        as="h1"
        align="center"
        color="content-heading-default"
        weight="semibold"
        className="hidden md:block"
      >
        {title}
      </Header>
      <SupportWidgetsWithFaqs />
    </Page>
  );
};
