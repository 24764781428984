'use client';

import {
  HTMLKamonaProps,
  createComponent,
  kamona,
} from '@kamona/styled-system';

type SymbolLogoProps = HTMLKamonaProps<'svg'>;

export const SymbolLogo = createComponent<SymbolLogoProps>((props, ref) => {
  return (
    <kamona.svg ref={ref} {...props} viewBox="0 0 120 120">
      <path
        d="M59.7988 120C47.6113 119.969 36.5479 118.724 27.8799 116.893C23.5469 115.978 19.7988 114.912 16.8022 113.772C13.8315 112.642 11.5205 111.409 10.126 110.119C8.36328 108.541 6.89258 105.797 5.66113 102.403C4.41699 98.9758 3.37647 94.7671 2.54053 90.145C0.868164 80.8986 0 69.9066 0 60.0062C0 48.362 1.06494 37.1862 2.84229 28.2732C3.73047 23.8177 4.80078 19.908 6.01416 16.7798C7.21631 13.682 8.59717 11.2471 10.1631 9.84339C11.5493 8.56233 13.854 7.33643 16.8184 6.21162C19.8096 5.07684 23.5537 4.01532 27.8833 3.10218C36.5444 1.27564 47.605 0.0313986 59.7886 0H60.2012C72.3887 0.0313986 83.4526 1.27564 92.1201 3.10698C96.4531 4.02246 100.201 5.08755 103.198 6.22787C106.168 7.3581 108.479 8.59101 109.875 9.88094C111.432 11.276 112.806 13.7048 114.001 16.7963C115.21 19.9192 116.276 23.8253 117.163 28.2783C118.935 37.1864 120 48.362 120 60.0062C120 71.6503 118.938 82.8042 117.167 91.7015C116.282 96.149 115.216 100.052 114.007 103.179C112.81 106.277 111.435 108.714 109.877 110.129C108.48 111.432 106.167 112.675 103.196 113.812C100.199 114.959 96.4526 116.027 92.1216 116.942C83.4575 118.772 72.3994 120 60.2114 120H59.7988Z"
        fill="currentColor"
        data-color="bg"
      />
      <path
        d="M88.7008 63.6203L86.3005 35H71.8009C71.8009 35 60.7623 57.0367 59.7854 60.0342H59.6319L47.9932 35H33.4238L31.0235 59.8152L29 85H37.8616C41.6854 85 43.1925 83.3849 43.4996 79.4429C43.4996 79.4429 45.0067 56.3797 44.7695 53.0126H44.923L45.3696 54.5456C45.9697 56.5987 54.2312 76.2948 54.2312 76.2948H65.1163C65.1163 76.2948 73.3779 56.8998 74.4245 53.0126H74.578L75.848 79.4429C76.155 83.3986 77.7319 85 81.4859 85H90.3475L88.7008 63.6203Z"
        fill="currentColor"
        data-color="fg"
      />
    </kamona.svg>
  );
});
