'use client';

import {
  HTMLKamonaVariantsAwareProps,
  createComponent,
  useBlueprint,
} from '@kamona/styled-system';
import { FC } from 'react';

import { CombinedLogo } from './combined-logo';
import { LogoVariants, logoVariants } from './logo.cx';
import { SymbolLogo } from './symbol-logo';

export interface LogoProps
  extends HTMLKamonaVariantsAwareProps<'svg', LogoVariants> {}

const variantToComponentMap = {
  combined: CombinedLogo,
  symbol: SymbolLogo,
} satisfies Record<NonNullable<LogoProps['variant']>, FC>;

export const MonieWorldLogo = createComponent<LogoProps>((props, ref) => {
  const blueprint = useBlueprint(props, logoVariants);
  const Component = variantToComponentMap[props.variant || 'combined'];

  if (!Component) return null;

  return <Component {...blueprint.ownProps} ref={ref} data-testid="logo" />;
});

MonieWorldLogo.displayName = 'MonieWorldLogo';
