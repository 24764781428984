import { cn } from '@/lib/utils';

export type BaseHeaderProps = React.ComponentPropsWithoutRef<'header'> & {
  borderless?: boolean;
  leftElement?: React.ReactNode;
  rightElement?: React.ReactNode;
};

export function BaseHeader({
  children,
  className,
  leftElement,
  rightElement,
  borderless = false,
}: BaseHeaderProps) {
  return (
    <header
      role="banner"
      className={cn(
        'relative flex items-center justify-between px-4 md:px-20 min-h-14 md:min-h-20',
        {
          'md:border-b border-grey/L3': !borderless,
        },
        className,
      )}
    >
      {leftElement && <div className="flex-1">{leftElement}</div>}
      {children}
      {rightElement && (
        <div className="flex-1 flex justify-end">{rightElement}</div>
      )}
    </header>
  );
}
