import { defineVariants } from '@kamona/styled-system';

export type LogoVariants = typeof logoVariants;

export const logoVariants = defineVariants({
  base: 'inline-block',
  variants: {
    color: {
      primary: [
        '[&>[data-color=fg]]:text-general-neutral-white',
        '[&>[data-color=bg]]:text-moniepoint-primary-base',
      ],
      inversed: [
        '[&>[data-color=fg]]:text-moniepoint-primary-base',
        '[&>[data-color=bg]]:text-general-neutral-white',
      ],
      white: [
        '[&>[data-color=fg]]:text-general-neutral-black',
        '[&>[data-color=bg]]:text-general-neutral-white',
      ],
      black: [
        '[&>[data-color=fg]]:text-general-neutral-white',
        '[&>[data-color=bg]]:text-general-neutral-black',
      ],
    },
    variant: {
      symbol: '',
      combined: '',
    },
    size: {
      small: '',
      medium: '',
      large: '',
    },
  },
  compoundVariants: [
    // sizes
    {
      size: 'small',
      variant: 'symbol',
      className: 'h-units-unit32',
    },
    {
      size: 'medium',
      variant: 'symbol',
      className: 'h-units-unit64',
    },
    {
      size: 'large',
      variant: 'symbol',
      className: 'h-[120px]',
    },
    {
      size: 'small',
      variant: 'combined',
      className: 'h-units-unit24',
    },
    {
      size: 'medium',
      variant: 'combined',
      className: 'h-units-unit40',
    },
    {
      size: 'large',
      variant: 'combined',
      className: 'h-units-unit64',
    },
  ],
  defaultVariants: {
    variant: 'combined',
    size: 'medium',
    color: 'primary',
  },
});
