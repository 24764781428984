'use client';

import {
  AlertModal,
  AlertModalProps,
  Body,
  Column,
  LinkButton,
} from '@kamona/components';
import { useState } from 'react';

import { ROUTES } from '@/shared/routes';

import { HeaderButtonChip } from './header-chip';

type HeaderWhyWeAskChipProps = {
  className?: string;
};

const getAlertModalProps = (
  navigateToTermsAndConditions: () => void,
  navigateToPrivacyPolicy: () => void,
): AlertModalProps => ({
  title: 'Why are we asking you for this information?',
  contentProps: { as: 'div' },
  description: (
    <Column className="gap-y-units-unit24 mt-units-unit24">
      <Body as="span">
        Keeping your information up to date is important for several reasons:
        <ul className="list-disc pl-units-unit14 flex flex-col gap-units-unit10 mt-units-unit10">
          <li>
            <span className="font-580">
              To comply with laws and regulations.
            </span>{' '}
            We&apos;re obliged to ask you these questions so that we abide by
            the laws and regulations that allow us to operate.
          </li>
          <li>
            <span className="font-580"> To safeguard your account. </span>{' '}
            Understanding your income and what kind of spending is normal for
            you helps us keep your account secure and offer the right services
            to you.
          </li>
        </ul>
      </Body>
      <Body as="span">
        For more information, see our 
        <LinkButton size="medium" onClick={navigateToTermsAndConditions}>
          Terms & Conditions.
        </LinkButton>
      </Body>
      <Body as="span">
        To understand how we handle your data, see our 
        <LinkButton size="medium" onClick={navigateToPrivacyPolicy}>
          Privacy Notice.
        </LinkButton>
      </Body>
    </Column>
  ),
  disableAutoIcon: true,
  dismissOnOutsideClick: false,
  dismissOnEscapeKeyPressed: true,
  backdrop: 'normal',
  cancelLabel: null,
  size: 'medium',
  acceptLabel: 'Okay',
  acceptButtonProps: {
    block: true,
    size: 'medium',
  },
  className: {
    content: 'pb-units-unit8 pt-units-unit32',
  },
});

export function HeaderWhyWeAskChip({
  className,
}: Readonly<HeaderWhyWeAskChipProps>) {
  const [open, setOpen] = useState(false);

  const navigateToTermsAndConditions = () =>
    window.open(ROUTES.TERMS_OF_USE.INDEX, '_blank');

  const navigateToPrivacyPolicy = () =>
    window.open(ROUTES.PRIVACY_POLICY.INDEX, '_blank');

  return (
    <>
      <HeaderButtonChip
        label="Why we ask"
        onClick={() => setOpen(true)}
        className={className}
      />
      <AlertModal
        {...getAlertModalProps(
          navigateToTermsAndConditions,
          navigateToPrivacyPolicy,
        )}
        open={open}
        onClose={() => setOpen(false)}
      />
    </>
  );
}
